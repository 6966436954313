import React, { useEffect } from "react";
import {
  Outlet,
  useLoaderData,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import Card from "../../../../UI/Card";
import { useMediaQuery } from "../../../../../utils/useMediaQuery";
import { useMobileNavigation } from "../../../../../contexts/MobileNavigationContext";
import { fullName } from "../../../../../utils/fullName";
import StudentCourseNav from "./StudentCourseNav";

export default function StudentCourse() {
  const isMobile = useMediaQuery("md", "down");
  const { user, student } = useOutletContext();
  const enrollment = useLoaderData().data;
  const seriesEnrollment = "courseEnrollment";
  const params = useParams();
  const studentId = params.studentId;
  const { setThirdMobileNavigation } = useMobileNavigation();

  useEffect(() => {
    if (student && enrollment && isMobile) {
      setThirdMobileNavigation({
        title: enrollment.series.name,
        subtitle: fullName(student?.first_name, " ", student?.last_name),
        links: [
          {
            name: "Lessons",
            href: `/students/${enrollment?.student_id}/courses/enrollments/${enrollment.id}/lessons`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Decisions",
            href: `/students/${enrollment?.student_id}/courses/enrollments/${enrollment.id}/decisions`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Questions",
            href: `/students/${enrollment?.student_id}/courses/enrollments/${enrollment.id}/questions`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Products",
            href: `/students/${enrollment?.student_id}/courses/enrollments/${enrollment.id}/products`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Reminders",
            href: `/students/${enrollment?.student_id}/courses/enrollments/${enrollment.id}/reminders`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Visits",
            href: `/students/${enrollment?.student_id}/courses/enrollments/${enrollment.id}/visits`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Management",
            href: `/students/${enrollment?.student_id}/courses/enrollments/${enrollment.id}/management`,
            show: true, // TODO: Add permission check
          },
        ],
      });
    }

    return () => {
      setThirdMobileNavigation(null);
    };
  }, [enrollment, student]);

  return isMobile ? (
    <>
      <div className="flex items-center gap-2">
        <Link to={`/students/${enrollment?.student_id}/courses`}>
          <ArrowLeftIcon className="mr-1 w-7" />
        </Link>
        <span className="tracking-tight leading-none text-[24px] text-center flex-1">
          {enrollment?.series.name}
        </span>
        <span className="w-7" />
      </div>

      <div className="mt-4">
        <Outlet context={{ user, seriesEnrollment }} />
      </div>
    </>
  ) : (
    <Card>
      <StudentCourseNav enrollmentId={enrollment?.id} studentId={studentId} />

      <h1 className="text-2xl">{enrollment?.series.name}</h1>

      <div className="mt-6">
        <Outlet context={{ user, seriesEnrollment }} />
      </div>
    </Card>
  );
}
