import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import Button from "../../../../../../UI/Button";
import Input from "../../../../../../UI/Inputs/Input";
import PageLoader from "../../../../../../UI/PageLoader";
import {
  createQuestionResponseRequest,
  updateQuestionResponseRequest,
} from "../../../../../../../requests/questionResponses";

export default function QuestionForm({
  enrollment,
  question,
  setFormOpen,
  open,
  reloadRecords,
}) {
  const response = question.response;
  const methods = useForm();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setFormOpen(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const res = response
        ? await updateQuestionResponseRequest({
            ...data,
            responseId: response?.id,
          })
        : await createQuestionResponseRequest({
            ...data,
            question_id: question.id,
            enrollment_id: enrollment.id,
          });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        handleClose();
        reloadRecords();
      }
    } catch (e) {
      ToastNotification("error", e.message);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <PageLoader className="h-[200px]" variant="primary" />
  ) : (
    <div
      className={twMerge(
        "transform transition-all duration-300 ease-in-out origin-top",
        open ? "h-auto opacity-100 scale-y-100" : "h-0 opacity-0 scale-y-0"
      )}
    >
      {open && (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="p-4 flex flex-col gap-4 border-t border-gray-500/10 mt-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Input
              id="response"
              placeholder="Response"
              defaultValue={response?.response}
              isRequired
              classNames="mb-4"
            />

            <div className="flex gap-4 justify-center">
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
}
