import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import CardLink from "../../../../UI/Card/CardLink";
import PageLoader from "../../../../UI/PageLoader";
import { twMerge } from "tailwind-merge";

export default function StudentMobileCourses({
  courses,
  returnStudentEnrollment,
  handleEnrollment,
}) {
  const student = useRouteLoaderData("student").data;

  if (!courses) return <PageLoader className="h-[50vh]" />;

  // Transform courses into enhanced objects with enrollment and graduation status
  const sortedCourses = courses
    .map((course) => ({
      ...course,
      enrollment: returnStudentEnrollment(course.id),
      graduated: returnStudentEnrollment(course.id)?.status === "Graduated",
    }))
    .sort((a, b) => {
      // First sort by graduation status (graduated courses last)
      if (a.graduated !== b.graduated) {
        return Number(a.graduated) - Number(b.graduated);
      }
      // Then sort by enrollment status (enrolled courses first)
      return !!b.enrollment - !!a.enrollment;
    });

  const daysPast = (enrollment) => {
    const dateSent =
      enrollment.latest_presentation_date_sent || enrollment.created_at;

    const days = Math.floor(
      (new Date() - new Date(dateSent)) / (1000 * 60 * 60 * 24)
    );

    return days > 0 ? days : 0;
  };

  return sortedCourses?.length ? (
    sortedCourses.map((course) => (
      <CardLink
        key={course.id}
        to={
          course.enrollment &&
          `/students/${student?.id}/courses/enrollments/${course.enrollment.id}/lessons`
        }
        className="mb-4 last:mb-10 block"
        cardClassName={twMerge("rounded-2xl p-4", course.graduated && "py-6")}
        dark={course.graduated}
      >
        <span className="flex justify-between items-center gap-2">
          <span className="text-[17px] leading-tight">{course.name}</span>
          {course.graduated ? (
            <span className="text-center text-xs">
              <span className="block whitespace-nowrap">Graduated</span>
              <span className="block whitespace-nowrap">
                {Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  timeZone: "UTC",
                }).format(new Date(course.enrollment.diploma_delivered_date))}
              </span>
            </span>
          ) : course.enrollment ? (
            <span className="flex text-center gap-4">
              <span>
                <span className="block text-xs whitespace-nowrap">Lesson</span>
                <span className="block text-[40px] leading-none">
                  {course.enrollment?.cached_attended_count + 1}
                </span>
              </span>
              <span className="flex gap-2">
                <span>
                  <span className="block text-xs whitespace-nowrap">
                    Days Past
                  </span>
                  <span className="block text-[40px] leading-none">
                    {daysPast(course.enrollment)}
                  </span>
                </span>
                <span className="hidden w-4">
                  <span className="rounded-full bg-[#35CDBB] px-2 py-1 text-xs">
                    {1}
                  </span>
                </span>
              </span>
            </span>
          ) : (
            <button
              className="text-primary text-xs border border-primary rounded-md px-2 py-1"
              onClick={() => handleEnrollment(course.id)}
            >
              Enroll
            </button>
          )}
        </span>
      </CardLink>
    ))
  ) : (
    <div className="text-center py-10 text-gray-500">No courses found.</div>
  );
}
