import React from "react";
import BackLink from "../../../../Mobile/BackLink";
import { useRouteLoaderData } from "react-router-dom";
import CardLink from "../../../../UI/Card/CardLink";
import PageLoader from "../../../../UI/PageLoader";

export default function StudentMobileNotes({
  notes,
  seriesTypePlural,
  notableTypePlural,
}) {
  if (!notes) return <PageLoader className="h-[60vh]" />;

  const student = useRouteLoaderData("student").data;

  const noteTypes = {
    Note: "Note",
    PrayerRequest: "Prayer Request",
  };

  return notes.length ? (
    <>
      <BackLink path={`/students/${student?.id}/overview`} label="Notes" />

      <div className="pt-4">
        {notes.map((note) => (
          <CardLink
            key={note.id}
            to={`/students/${student?.id}/${seriesTypePlural(
              note.series_type
            )}/enrollments/${note.enrollment_id}/${notableTypePlural(note)}`}
            className="mb-4 last:mb-0 block"
            cardClassName="px-4 rounded-2xl flex items-center justify-between gap-4"
            dark
          >
            <span>
              <span className="text-xs block">{noteTypes[note.note_type]}</span>
              <span className="text-lg block">{note.text}</span>
              <span className="text-sm block">{note.context}</span>
            </span>
          </CardLink>
        ))}
      </div>
    </>
  ) : (
    <div className="text-center py-10 text-gray-500">
      No notes found for this student.
    </div>
  );
}
