import React from "react";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import CardHeader from "../../../UI/CardHeader";
import { Link } from "react-router-dom";
import { hoverLink } from "../../../../utils/hoverLink";

export default function CoursesTable({ instructor, courses, needingFollowup }) {
  const getFollowupCount = (courseId) => {
    const followups = needingFollowup?.filter(
      (followup) => followup.series_id === courseId
    );

    return followups?.length;
  };

  return (
    <Card classNames="overflow-y-scroll no-scrollbar">
      <CardHeader
        title="Courses"
        to={`/instructors/${instructor.id}/courses`}
      />

      {courses?.length ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>COURSE</TableHeader>
              <TableHeader>STUDENTS</TableHeader>
              <TableHeader>NEEDING-FOLLOW-UP</TableHeader>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr key={course.id}>
                <TableCell>
                  <Link
                    className={hoverLink}
                    to={`/courses/${course.id}/overview`}
                  >
                    {course.name}
                  </Link>
                </TableCell>
                <TableCell>{course.students_count}</TableCell>
                <TableCell>{getFollowupCount(course.id)}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No courses for this instructor." />
      )}
    </Card>
  );
}
