import React, { useEffect, useState } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import { getAllUserCoursesRequest } from "../../../../requests/users";
import { getNeedingFollowupRequest } from "../../../../requests/enrollments";
import StudentsTable from "./StudentsTable";
import CoursesTable from "./CoursesTable";
import InstructorMobileOverview from "./Mobile/InstructorMobileOverview";

export default function InstructorOverview() {
  const isMobile = useMediaQuery("md", "down");
  const instructor = useRouteLoaderData("user").data;
  const [courses, setCourses] = useState();
  const [needingFollowup, setNeedingFollowup] = useState();

  const getCourses = async () => {
    const res = await getAllUserCoursesRequest({
      userId: instructor.id,
    });

    setCourses(res.data);
  };

  const getNeedingFollowup = async () => {
    const res = await getNeedingFollowupRequest({
      params: { instructor_id: instructor.id },
    });

    if (res.data) {
      setNeedingFollowup(res.data);
    }
  };

  useEffect(() => {
    getCourses();
    getNeedingFollowup();
  }, []);

  return isMobile ? (
    <InstructorMobileOverview
      courses={courses}
      needingFollowup={needingFollowup}
    />
  ) : (
    <div className="flex">
      <div className="w-2/4 mr-5">
        <StudentsTable
          instructor={instructor}
          needingFollowup={needingFollowup}
        />
      </div>
      <div className="w-2/4">
        <CoursesTable
          instructor={instructor}
          courses={courses}
          needingFollowup={needingFollowup}
        />
      </div>
    </div>
  );
}
