import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { twMerge } from "tailwind-merge";
import convertUTCToLocalDate from "../../../utils/dateAndTime/convertUTCToLocalDate";

export default function DatePickerInput({
  id,
  classNames,
  selected,
  label,
  dark,
  disabled,
  isRequired,
  autoFocus = false,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      {label && <label htmlFor={id}>{label}</label>}
      <Controller
        control={control}
        name={id}
        defaultValue={selected}
        rules={{
          required: isRequired
            ? typeof isRequired === "string"
              ? isRequired
              : "This field is required"
            : false,
        }}
        render={({ field }) => (
          <ReactDatePicker
            id={id}
            autoFocus={autoFocus}
            disabled={disabled}
            className={twMerge(
              "border border-gray-200 px-2 py-1.5 rounded w-full",
              dark && "bg-transparent",
              errors[id] && "border-red-500",
              classNames
            )}
            selected={convertUTCToLocalDate(field.value)}
            onChange={(date) => field.onChange(date)}
            placeholderText="00/00/0000"
          />
        )}
      />

      {errors[id] && (
        <p className="absolute text-red-500">{errors[id]?.message}</p>
      )}
    </div>
  );
}
