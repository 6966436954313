import React, { useEffect, useState } from "react";
import InputGroup from "../../UI/Inputs/InputGroup";
import { getAllStudents, getStudentRequest } from "../../../requests/students";
import SearchAsync from "../../UI/SearchAsync";

export default function StudentSelect({ onChange, loaderStudent }) {
  const [defaultValue, setDefaultValue] = useState(null);

  const loadOptions = async (inputValue) => {
    const res = await getAllStudents({
      params: { q: { full_name_i_cont_all: inputValue.trim().split(" ") } },
    });

    return res.data.students.map((student) => ({
      label: `${student.first_name} ${student.last_name}`,
      value: student.id,
    }));
  };

  const handleChange = async (selected) => {
    onChange(null);
    const res = await getStudentRequest({ studentId: selected.value });
    onChange(res.data);
  };

  useEffect(() => {
    if (loaderStudent) {
      const defaultOption = {
        label: `${loaderStudent.first_name} ${loaderStudent.last_name}`,
        value: loaderStudent.id,
      };
      setDefaultValue(defaultOption);
      onChange(loaderStudent);
    }
  }, [loaderStudent]);

  return (
    <div className="lg:w-1/2">
      <label>Student</label>
      <InputGroup>
        <SearchAsync
          loadOptions={loadOptions}
          onChange={handleChange}
          placeholder="Search for a student"
          className="border rounded"
          defaultValue={defaultValue}
        />
      </InputGroup>
    </div>
  );
}
