import React from "react";
import CardLink from "../../../../../../UI/Card/CardLink";
import PageLoader from "../../../../../../UI/PageLoader";

export default function DecisionsMobile({ decisions }) {
  if (!decisions) return <PageLoader className="h-[60vh]" />;

  return decisions.length ? (
    <div className="pt-4">
      {decisions.map((decision) => (
        <CardLink
          key={decision.id}
          className="mb-4 last:mb-0 block"
          cardClassName="px-4 rounded-2xl flex items-center justify-between gap-4"
          dark
        >
          <span>
            <span className="text-lg block">
              {decision.decision_option.name}
            </span>
            <span className="text-sm block">{decision.context}</span>
          </span>
          {(decision.owner.date_sent || decision.owner.date) && (
            <span className="text-xs whitespace-nowrap">
              {Intl.DateTimeFormat("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                timeZone: "UTC",
              }).format(
                new Date(decision.owner.date_sent || decision.owner.date)
              )}
            </span>
          )}
        </CardLink>
      ))}
    </div>
  ) : (
    <div className="text-center py-10 text-gray-500">
      No decisions found for this course.
    </div>
  );
}
