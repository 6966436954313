import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import { displayError } from "../../../../utils/displayError";
import { getAllSchoolStudents } from "../../../../requests/schools";
import {
  Link,
  useOutletContext,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import Card from "../../../UI/Card";
import { checkPermission } from "../../../../utils/checkPermission";
import QuickFind from "../../../UI/QuickFind";
import TableHeader from "../../../UI/Table/TableHeader";
import Table from "../../../UI/Table/Table";
import EmptyTable from "../../../UI/EmptyTable";
import { fullName } from "../../../../utils/fullName";
import { highlightText } from "../../../../utils/highlightText";
import { hoverLink } from "../../../../utils/hoverLink";
import PageLoader from "../../../UI/PageLoader";
import TableCell from "../../../UI/Table/TableCell";
import StudentEnrollmentsCell from "../../../UI/StudentEnrollmentsCell";
import { addressMultiLine } from "../../../../utils/fullAddress";
import SchoolStudentsMobile from "./Mobile/SchoolStudentsMobile";

export default function SchoolStudents() {
  const isMobile = useMediaQuery("md", "down");
  const { setActionMenuModal } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const sortBy = searchParams.get("sortBy") || "created_at";
  const orderBy = searchParams.get("orderBy") || "asc";
  const school = useRouteLoaderData("school").data;
  const [students, setStudents] = useState();
  const [loading, setLoading] = useState(false);

  const getParams = () => {
    const queryParams = {
      q: { sorts: `${sortBy} ${orderBy}` },
    };

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      queryParams.q.full_name_i_cont_all = query.trim().split(" ");
    }

    return queryParams;
  };

  const getSchoolInstructors = async () => {
    setLoading(true);

    try {
      const res = await getAllSchoolStudents({
        school_id: school.id,
        ...getParams(),
      });

      if (res.data) {
        setStudents(res.data);
      }
    } catch (e) {
      displayError(e);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("query", data.search || "");
      return prev;
    });
  };

  // Fetch students when the component mounts
  // and when pagination or section changes.
  useEffect(() => {
    getSchoolInstructors();
  }, [searchParams]);

  return isMobile ? (
    <SchoolStudentsMobile students={students} />
  ) : (
    <>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["school_admin", "conference_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() =>
                  setActionMenuModal({
                    open: true,
                    defaultRender: "NewStudent",
                  })
                }
              />
            )}

            <h1 className="text-xl font-bold text-primary">STUDENTS</h1>
          </div>
          <div className="flex">
            <QuickFind onSubmit={onSubmit} value={query} />
          </div>
        </div>
        {students?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>STUDENT</TableHeader>
                <TableHeader>ENROLLMENTS</TableHeader>
                <TableHeader
                  isSortable
                  isMultiSort
                  multiSortOptions={[
                    { name: "City", value: "city" },
                    { name: "State", value: "state" },
                    { name: "Zip", value: "zip_code" },
                    { name: "Country", value: "country" },
                  ]}
                >
                  ADDRESS
                </TableHeader>
                <TableHeader>CREATED</TableHeader>
                <TableHeader>INSTRUCTOR</TableHeader>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id} className={loading ? "opacity-50" : ""}>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/students/${student.id}/overview`}
                    >
                      {highlightText(
                        fullName(student.first_name, " ", student.last_name),
                        query
                      )}
                    </Link>
                  </TableCell>
                  <TableCell classNames="text-sm min-w-[300px]">
                    <StudentEnrollmentsCell student={student} />
                  </TableCell>
                  <TableCell classNames="whitespace-nowrap">
                    {addressMultiLine(
                      {
                        address: student.address,
                        address_2: student.address_2,
                        city: student.city,
                        state: student.state,
                        country: student.country,
                        zip_code: student.zip_code,
                      },
                      { highlight: searchParams.get("sortBy") }
                    )}
                  </TableCell>
                  <TableCell>
                    {dayjs(student.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/instructors/${student.instructor_id}/overview`}
                    >
                      {student.instructor_name}
                    </Link>
                    {student.student_shares.length > 0 && (
                      <ul className="ml-2 text-xs text-yellow-500">
                        {student.student_shares.map((share) => (
                          <li key={share.id}>{share.user_name}</li>
                        ))}
                      </ul>
                    )}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : students ? (
          <EmptyTable message="No students found." />
        ) : (
          <PageLoader />
        )}
      </Card>
    </>
  );
}
