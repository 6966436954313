import React from "react";
import CardLink from "../../../../UI/Card/CardLink";
import PageLoader from "../../../../UI/PageLoader";
import BackLink from "../../../../Mobile/BackLink";

export default function SchoolSeminarsMobile({ seminars, isGlobalAdmin }) {
  if (!seminars) return <PageLoader />;

  return (
    <>
      <BackLink path="/schools" label="Seminars" />

      {seminars?.length ? (
        <div className="pt-4">
          {seminars?.map((seminar) => (
            <CardLink
              key={seminar.id}
              to={
                isGlobalAdmin
                  ? `/seminars/${seminar.id}/details`
                  : `/students?q%5Benrollments_seminar_id_eq%5D=${seminar.id}`
              }
              className="mb-4 last:mb-10 block"
              cardClassName="rounded-xl p-0 pb-2"
            >
              <span className="block px-4 pt-4 pb-2 border-b border-gray-500/20">
                <span className="block text-lg leading-tight">
                  {seminar.name}
                </span>
              </span>
              <span className="flex justify-center items-start py-2 m-auto">
                <span className="w-1/3 text-center">
                  <span className="block text-[40px] leading-tight">
                    {seminar.active_students_count}
                  </span>
                  <span className="block text-xs leading-none">
                    Active Students
                  </span>
                </span>
                <span className="w-1/3 text-center">
                  <span className="block text-[40px] leading-tight">
                    {seminar.students_count}
                  </span>
                  <span className="block text-xs leading-none">Students</span>
                </span>
                <span className="w-1/3 text-center">
                  <span className="block text-[40px] leading-tight">
                    {seminar.presentations_count}
                  </span>
                  <span className="block text-xs leading-none">
                    Presentations
                  </span>
                </span>
              </span>
            </CardLink>
          ))}
        </div>
      ) : (
        <div className="text-center py-10 text-gray-500">
          No seminars found.
        </div>
      )}
    </>
  );
}
