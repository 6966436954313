import React, { createContext, useContext, useState } from "react";

const MobileNavigationContext = createContext();

export const MobileNavigationProvider = ({ children }) => {
  const [mobileNavigation, setMobileNavigation] = useState();
  const [thirdMobileNavigation, setThirdMobileNavigation] = useState();

  const value = {
    mobileNavigation,
    setMobileNavigation,
    thirdMobileNavigation,
    setThirdMobileNavigation,
  };

  return (
    <MobileNavigationContext.Provider value={value}>
      {children}
    </MobileNavigationContext.Provider>
  );
};

export const useMobileNavigation = () => {
  const context = useContext(MobileNavigationContext);
  if (!context) {
    throw new Error(
      "useMobileNavigation must be used within a MobileNavigationProvider"
    );
  }
  return context;
};
