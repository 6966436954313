import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../UI/Inputs/Input";
import InputGroup from "../../../UI/Inputs/InputGroup";
import Button from "../../../UI/Button";

export default function CourseProductsForm({
  setOpen,
  product,
  onSubmit,
  column,
}) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex items-end">
          <InputGroup classNames="w-full md:w-auto">
            {(column === "sku" || column === "all") && (
              <Input
                id="sku"
                classNames="md:mr-4"
                placeholder="SKU"
                defaultValue={product?.sku}
                isRequired
              />
            )}

            {(column === "name" || column === "all") && (
              <Input
                id="name"
                classNames="mr-4"
                placeholder="Product Name"
                defaultValue={product?.name}
                isRequired
              />
            )}

            {(column === "price" || column === "all") && (
              <Input
                id="default_price"
                classNames="mr-4"
                placeholder="Default Price"
                defaultValue={product?.default_price}
                type="number"
                size="12"
                min="0.0"
                step="0.01"
                isRequired
              />
            )}

            <div>
              <Button
                onClick={() => setOpen({ open: false, action: null })}
                classNames="mr-4"
                variant="primary-outline"
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary-outline">
                Save
              </Button>
            </div>
          </InputGroup>
        </div>
      </form>
    </FormProvider>
  );
}
