import React, { useEffect, useState } from "react";
import { Pagination, usePagination } from "../UI/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import Card from "../UI/Card";
import QuickFind from "../UI/QuickFind";
import ListButtonGroup from "../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../UI/ListButtonGroup/ListButtonGroupItem";
import { ToastNotification } from "../UI/ToastNotification";
import {
  getAllSchools,
  downloadCsvSchoolsRequest,
} from "../../requests/schools";
import { checkPermission } from "../../utils/checkPermission";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { usePageActions } from "../../contexts/PageActionsContext";
import { DownloadCSVIcon, PrintIcon, SharpIcon } from "../UI/PageActions/Icons";
import SchoolsTable from "./SchoolsTable";
import { displayError } from "../../utils/displayError";
import { useFilterValues } from "../UI/Filter/Filter";
import SchoolsFilter from "./SchoolsFilter";
import SchoolsPrint from "./SchoolsPrint";
import ClaimedZipCodes from "./Actions/ClaimedZipCodes";
import SchoolsMobileList from "./Mobile/SchoolsMobileList";

export default function Schools() {
  const isMobile = useMediaQuery("md", "down");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const pagination = usePagination();
  const { setPageActions } = usePageActions();
  const filterValues = useFilterValues();
  const section = searchParams.get("section") || "all";
  const page = searchParams.get("page") || "1";
  const query = searchParams.get("query") || "";
  const sortBy = searchParams.get("sortBy") || "created_at";
  const orderBy = searchParams.get("orderBy") || "asc";
  const [schools, setSchools] = useState();
  const [extraData, setExtraData] = useState();
  const [loading, setLoading] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [printingSchools, setPrintingSchools] = useState([]);
  const [claimedZipCodesOpen, setClaimedZipCodesOpen] = useState(false);

  const tableNavigation = [
    { label: `All (${extraData?.total_all ?? "#"})`, name: "all" },
    { label: `Active (${extraData?.total_active ?? "#"})`, name: "active" },
    { label: `Archive (${extraData?.total_inactive ?? "#"})`, name: "archive" },
  ];

  const getParams = ({ print = false, csv = false } = {}) => {
    const queryParams = {
      section: section,
      page: csv || print ? undefined : page,
      per_page: print || csv ? undefined : pagination.recordsPerPage,
      pagination: !print && !csv,
      q: { ...filterValues, sorts: `${sortBy} ${orderBy}` },
    };

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      queryParams.q.school_name_or_school_code_or_church_name_i_cont_all = query
        .trim()
        .replace("#", "")
        .split(" ");
    }

    return queryParams;
  };

  const fetchSchools = async (callback) => {
    setLoading(true);

    try {
      const res = await getAllSchools({
        params: getParams({ print: Boolean(callback) }),
      });

      if (res.data) {
        if (callback) {
          callback(res.data.schools);
        } else {
          setSchools(res.data.schools);
          pagination.setData(res.data.pagination);
          setExtraData(res.data.extra);
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSection = (section) => {
    setSearchParams((prev) => {
      prev.set("section", section);
      prev.set("page", "1");
      prev.set("query", "");
      return prev;
    });
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("section", section);
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  const handleDownloadCsv = async () => {
    setDownloadingCsv(true);
    const res = await downloadCsvSchoolsRequest({
      params: getParams({ csv: true }),
    });

    if (!res.success) {
      ToastNotification("error", "Failed to download CSV");
    }

    setDownloadingCsv(false);
  };

  const handlePrintRecords = () => {
    setIsPrinting(true);

    fetchSchools((schools) => {
      setPrintingSchools(schools);
      setLoading(false);
      setIsPrinting(false);
    });
  };

  // Fetch schools when the component mounts
  // and when query params change.
  useEffect(() => {
    fetchSchools();
  }, [searchParams, pagination.recordsPerPage]);

  useEffect(() => {
    setPageActions([
      {
        icon: SharpIcon,
        label: "Claimed Zip Codes",
        onClick: () => {
          setClaimedZipCodesOpen(true);
        },
      },
      {
        icon: DownloadCSVIcon,
        disabled: loading || downloadingCsv || !schools?.length,
        loading: downloadingCsv,
        label: "Download CSV",
        onClick: handleDownloadCsv,
      },
      {
        icon: PrintIcon,
        label: "Print Records",
        disabled: loading || isPrinting || !schools?.length,
        loading: isPrinting,
        onClick: handlePrintRecords,
      },
    ]);

    return () => {
      setPageActions([]);
    };
  }, [
    setPageActions,
    loading,
    downloadingCsv,
    isPrinting,
    schools,
    searchParams,
  ]);

  useEffect(() => {
    if (printingSchools.length > 0) window.print();

    return () => {
      if (printingSchools.length > 0) setPrintingSchools([]);
    };
  }, [printingSchools]);

  return isMobile ? (
    <>
      <SchoolsMobileList
        schools={schools}
        loading={loading}
        tableNavigation={tableNavigation}
        handleSection={handleSection}
        section={section}
      />

      <Pagination
        pagination={pagination}
        loading={loading && !schools?.length}
        empty={!schools?.length}
      />
    </>
  ) : (
    <>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission("global_admin") && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() => navigate("/schools/new")}
              />
            )}
            <div className="flex items-center justify-between">
              <h1 className="text-xl font-bold text-primary mr-8">SCHOOLS</h1>
              <p>Total Schools: {extraData?.total_all ?? "#"}</p>
            </div>
          </div>
          <ListButtonGroup>
            {tableNavigation.map((item, index) => (
              <ListButtonGroupItem
                key={item.name}
                label={item.label}
                name={item.name}
                isFirst={index === 0}
                isLast={index === tableNavigation.length - 1}
                setSelected={handleSection}
                selected={section}
              />
            ))}
          </ListButtonGroup>
          <div className="flex">
            <QuickFind onSubmit={onSubmit} value={query} />
            <div className="relative ml-7">
              <SchoolsFilter />
            </div>
          </div>
        </div>
        <Pagination
          pagination={pagination}
          loading={loading && !schools?.length}
          empty={!schools?.length}
        >
          <SchoolsTable
            schools={schools}
            loading={loading}
            sortBy={sortBy}
            query={query}
          />
        </Pagination>
      </Card>

      <SchoolsPrint schools={printingSchools} />

      <ClaimedZipCodes
        open={claimedZipCodesOpen}
        setOpen={setClaimedZipCodesOpen}
      />
    </>
  );
}
