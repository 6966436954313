import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../../../UI/Button";
import Card from "../../../../../UI/Card";
import { useOutletContext, useRouteLoaderData } from "react-router-dom";
import { ToastNotification } from "../../../../../UI/ToastNotification";
import { updateEnrollmentRequest } from "../../../../../../requests/enrollments";
import { useMediaQuery } from "../../../../../../utils/useMediaQuery";
import CourseForm from "./CourseForm";
import SeminarForm from "./SeminarForm";

export default function Management() {
  const isMobile = useMediaQuery("md", "down");
  const { seriesEnrollment } = useOutletContext();
  const enrollment = useRouteLoaderData(seriesEnrollment).data;

  const methods = useForm();

  const onSubmit = async (data) => {
    try {
      const res = await updateEnrollmentRequest({
        ...data,
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Enrollment updated successfully.");
      }
    } catch (e) {
      ToastNotification("error", e.message);
    }
  };

  const form = () => (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {seriesEnrollment === "courseEnrollment" ? (
          <CourseForm enrollment={enrollment} />
        ) : (
          <SeminarForm enrollment={enrollment} />
        )}
        <Button
          classNames="md:w-auto md:mr-4 md:mb-0 w-full mb-4"
          variant="primary"
          type="submit"
        >
          Update
        </Button>
      </form>
    </FormProvider>
  );

  return isMobile ? <Card classNames="p-3 rounded-2xl">{form()}</Card> : form();
}
