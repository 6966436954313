import React from "react";
import BackLink from "../../../../Mobile/BackLink";
import CardLink from "../../../../UI/Card/CardLink";
import { fullName } from "../../../../../utils/fullName";
import PageLoader from "../../../../UI/PageLoader";

export default function SchoolInstructorsMobile({ instructors }) {
  if (!instructors) return <PageLoader />;

  return (
    <div>
      <BackLink path="/schools" label="Instructors" />

      {instructors?.length ? (
        <div className="pt-4">
          {instructors?.map((instructor) => (
            <CardLink
              key={instructor.id}
              to={`/instructors/${instructor.id}/overview`}
              className="mb-4 last:mb-10 block"
              cardClassName="rounded-xl p-0 pb-2"
            >
              <span className="flex p-4 pb-2 justify-between border-b border-gray-500/20">
                {fullName(instructor.first_name, " ", instructor.last_name)}
              </span>

              {instructor.roles.map((role) => (
                <span
                  key={role.id}
                  className="flex px-4 pt-2 justify-between text-sm gap-4"
                >
                  <span>{role.titleized_role}</span>
                  <span>{role.organization_name}</span>
                </span>
              ))}
            </CardLink>
          ))}
        </div>
      ) : (
        <div className="text-center py-10 text-gray-500">
          No instructors found.
        </div>
      )}
    </div>
  );
}
