import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import CardLink from "../../../../../../UI/Card/CardLink";
import PageLoader from "../../../../../../UI/PageLoader";
import ProductForm from "./ProductFormMobile";

export default function ProductsMobile({
  deliveries,
  products,
  enrollment,
  setUpdatedEnrollment,
}) {
  if (!deliveries) return <PageLoader className="h-[60vh]" />;

  const [formOpen, setFormOpen] = useState();

  const sortedDeliveries = deliveries.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  return sortedDeliveries.length ? (
    sortedDeliveries.map((delivery) => (
      <CardLink
        key={delivery.id}
        className="mb-4 block"
        cardClassName={twMerge(
          "rounded-2xl p-0 py-2",
          delivery.completed && "[&_input]:text-black [&_select]:text-black",
          formOpen === delivery.id && "z-10"
        )}
        dark={delivery.completed}
        onClick={() => {
          setFormOpen(delivery.id);
        }}
        activeEffect={formOpen !== delivery.id}
      >
        <span
          className={twMerge(
            "flex items-center justify-between p-4",
            delivery.completed && "border-b border-gray-500/10"
          )}
        >
          <span className="block text-lg leading-none ">
            {delivery.product.name}
          </span>

          {delivery.delivered_at && (
            <span className="text-xs whitespace-nowrap">
              {Intl.DateTimeFormat("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                timeZone: "UTC",
              }).format(new Date(delivery.delivered_at))}
            </span>
          )}
        </span>

        <ProductForm
          open={formOpen === delivery.id}
          delivery={delivery}
          setFormOpen={setFormOpen}
          products={products}
          enrollment={enrollment}
          setUpdatedEnrollment={setUpdatedEnrollment}
        />
      </CardLink>
    ))
  ) : (
    <div className="text-center py-10 text-gray-500">
      No products found for this enrollment.
    </div>
  );
}
