import React, { useEffect, useRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Pill from "../Pill";
import { twMerge } from "tailwind-merge";

const normalizeOptions = (opts) => {
  return opts.map((opt) => {
    if (typeof opt === "string") {
      return { id: opt, name: opt };
    }
    return opt;
  });
};

const normalizeSelected = (sel) => {
  return sel.map((item) => {
    if (typeof item === "string") {
      return { id: item, name: item };
    }
    return item;
  });
};

export default function MultiSelect({
  id,
  placeholder,
  options,
  dark,
  label,
  defaultSelected = [],
  readOnly,
}) {
  const { setValue, control, watch } = useFormContext();
  const watchedValue = watch(id);
  const [selected, setSelected] = useState(normalizeSelected(defaultSelected));
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef();

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleSelect = (option) => {
    if (selected.some((item) => item.id === option.id)) {
      setSelected(selected.filter((item) => item.id !== option.id));
    } else {
      setSelected([...selected, option]);
    }
  };

  const handleFilter = (value) => {
    setOpen(true);
    const filteredOptions = options.filter((option) => {
      if (typeof option === "string") {
        return option.toLowerCase().includes(value.toLowerCase());
      } else {
        return option.name.toLowerCase().includes(value.toLowerCase());
      }
    });

    setDefaultOptions(normalizeOptions(filteredOptions));
  };

  useEffect(() => {
    setDefaultOptions(normalizeOptions(options));

    if (watchedValue && watchedValue.length > 0) {
      setSelected(normalizeSelected(watchedValue));
    }
  }, [options, defaultSelected]);

  useEffect(() => {
    const selectedValues = selected.map((item) =>
      typeof options[0] === "string" ? item.id : item
    );

    const selectedIds = selectedValues?.map((item) => item.id || item).sort();
    const watchedIds = watchedValue?.map((item) => item.id || item).sort();

    if (JSON.stringify(selectedIds) !== JSON.stringify(watchedIds)) {
      setValue(id, selectedValues);
    }
  }, [selected, id, setValue, options, watchedValue]);

  // closes on mouse leave
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="flex h-full w-full flex-col rounded-md text-popover-foreground overflow-visible"
      ref={wrapperRef}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <Controller
        id={id}
        control={control}
        name={id}
        defaultValue={selected}
        render={() => (
          <div
            className={twMerge(
              dark ? "bg-transparent" : "bg-white",
              "group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2"
            )}
          >
            <div className="flex flex-wrap gap-1">
              {selected.length > 0 &&
                selected.map((item) => (
                  <Pill key={item.id}>
                    {item.name}
                    {!readOnly && (
                      <div className="cursor-pointer bg-secondary-600 rounded-full w-6 md:w-4 ml-1 shadow-inner hover:bg-white hover:text-secondary">
                        <XMarkIcon
                          onClick={() =>
                            setSelected(
                              selected.filter(
                                (selectedItem) => selectedItem.id !== item.id
                              )
                            )
                          }
                        />
                      </div>
                    )}
                  </Pill>
                ))}
              <input
                id={id}
                placeholder={!readOnly ? placeholder : ""}
                className={twMerge(
                  dark && "placeholder-white",
                  "ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground pl"
                )}
                role="combobox"
                type="text"
                onChange={(e) => handleFilter(e.target.value)}
                onClick={() => !readOnly && setOpen(!open)}
                disabled={readOnly}
              />
            </div>
          </div>
        )}
      />
      <div className="relative mt-2 h-full">
        {open && (
          <ul className="absolute bg-white w-full max-h-[500px] rounded shadow-lg top-0 z-50 overflow-y-scroll no-scrollbar">
            {defaultOptions.length > 0 ? (
              defaultOptions.map((option) => (
                <li
                  key={option.id}
                  className="hover:bg-neutral-600 hover:text-white p-2 cursor-pointer text-black"
                  onClick={() => handleSelect(option)}
                >
                  <input
                    type="checkbox"
                    checked={selected.some((item) => item.id === option.id)}
                    onChange={() => handleSelect(option)}
                    className="h-4 w-4 mr-2 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                  />
                  {option.name}
                </li>
              ))
            ) : (
              <li className="p-2 text-black">No options available</li>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
