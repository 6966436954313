import React, { useEffect, useState } from "react";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import Card from "../../../UI/Card";
import { Cog8ToothIcon } from "@heroicons/react/20/solid";
import QuickFind from "../../../UI/QuickFind";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import Button from "../../../UI/Button";
import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import { getAllUserCoursesRequest } from "../../../../requests/users";
import EmptyTable from "../../../UI/EmptyTable";
import { hoverLink } from "../../../../utils/hoverLink";
import { checkPermission } from "../../../../utils/checkPermission";
import InstructorCoursesMobile from "./Mobile/InstructorCoursesMobile";

export default function InstructorCourses() {
  const isMobile = useMediaQuery("md", "down");
  const navigate = useNavigate();
  const instructor = useRouteLoaderData("user").data;
  const [courses, setCourses] = useState();

  const getCourses = async () => {
    const res = await getAllUserCoursesRequest({
      userId: instructor.id,
    });

    setCourses(res.data);
  };

  useEffect(() => {
    getCourses();
  }, []);

  return isMobile ? (
    <InstructorCoursesMobile courses={courses} />
  ) : (
    <Card>
      <div className="flex items-center justify-between mb-6">
        <div className="relative">
          {checkPermission("global_admin") && (
            <img
              src="/add-new.svg"
              className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
              width={30}
              height={30}
              alt="Add New"
              onClick={() => navigate("/courses/new")}
            />
          )}

          <h1 className="text-xl font-bold text-primary">COURSES</h1>
        </div>
        <div className="flex">
          <QuickFind />
          <Cog8ToothIcon className="ml-7 w-5 cursor-pointer" />
        </div>
      </div>
      {courses?.length ? (
        <Table>
          <thead>
            <tr>
              <TableHeader>COURSE</TableHeader>
              <TableHeader>LESSONS</TableHeader>
              <TableHeader>ACTIVE STUDENTS</TableHeader>
              <TableHeader>TOTAL STUDENTS</TableHeader>
              <TableHeader>PRODUCTS</TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr key={course.id}>
                <TableCell>
                  <Link
                    className={hoverLink}
                    to={`/courses/${course.id}/overview`}
                  >
                    {course.name}
                  </Link>
                </TableCell>
                <TableCell>{course.lessons_count}</TableCell>
                <TableCell>{course.active_students_count}</TableCell>
                <TableCell>{course.students_count}</TableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  <Button variant="primary-outline">Archive</Button>
                </TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable message="No courses found for this instructor." />
      )}
    </Card>
  );
}
