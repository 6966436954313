import React, { forwardRef, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { twMerge } from "tailwind-merge";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const SearchAsync = forwardRef(
  (
    {
      className,
      light = false,
      placeholder,
      onChange,
      loadOptions,
      defaultValue,
      inputBgWhite,
      onFocus,
      onBlur,
      onClick,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);

    const handleChange = (selectedOption) => {
      setValue(selectedOption);
      onChange?.(selectedOption);
    };

    const groupStyles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "8px 0",
      color: "#9ca3af",
    };

    const groupBadgeStyles = {
      backgroundColor: "#444",
      borderRadius: "2em",
      color: "#fff",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.17em 0.5em",
      textAlign: "center",
    };

    const formatGroupLabel = (data) => (
      <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
      </div>
    );

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: inputBgWhite ? "white" : "transparent",
        borderColor: state.isFocused ? "#0694C0" : "#6b7280",
        boxShadow: state.isFocused ? "0 0 0 1px #0694C0" : "none",
        "&:hover": {
          borderColor: "#0694C0",
        },
        minHeight: "38px",
        height: "38px",
        fontSize: "14px",
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: "0 6px",
      }),
      input: (provided) => ({
        ...provided,
        margin: "0px",
        color: light ? "#000" : "white",
        cursor: "text",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: light ? "#444" : "#9ca3af",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: light ? "#000" : "white",
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? "#444" : "transparent",
        color: state.isFocused
          ? light
            ? "white"
            : "white"
          : light
            ? "#d1d5db"
            : "white",
        cursor: "pointer",
        fontSize: "14px",
        "&:hover": {
          backgroundColor: "#444",
          color: light ? "white" : "white",
        },
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: "#333",
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
      }),
      indicatorSeparator: () => ({
        display: "none",
      }),
      dropdownIndicator: () => ({
        display: "none",
      }),
    };

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    return (
      <div className={twMerge("relative", className)}>
        <AsyncSelect
          {...props}
          ref={ref}
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder || `Search`}
          styles={customStyles}
          classNamePrefix="react-select"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            LoadingIndicator: () => null,
          }}
          formatGroupLabel={formatGroupLabel}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
        />
        <MagnifyingGlassIcon
          className={`absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5 pointer-events-none backdrop-blur-3xl rounded-full ${
            light ? "text-[#333]" : "text-gray-400"
          }`}
          aria-hidden="true"
        />
      </div>
    );
  }
);

SearchAsync.displayName = "SearchAsync";

export default SearchAsync;
