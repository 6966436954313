import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import DatePickerInput from "../../../../../../UI/Inputs/DatePickerInput";
import Select from "../../../../../../UI/Inputs/Select/Select";
import SelectItem from "../../../../../../UI/Inputs/Select/SelectItem";
import Input from "../../../../../../UI/Inputs/Input";
import Button from "../../../../../../UI/Button";
import PageLoader from "../../../../../../UI/PageLoader";
import Modal from "../../../../../../UI/Modal";
import ConfirmationDialog from "../../../../../../UI/ConfirmationDialog";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";

const deliveryTypes = [
  {
    label: "PO",
    value: "Post Office",
  },
  {
    label: "PD",
    value: "Personal Delivery",
  },
  {
    label: "IH",
    value: "In-home",
  },
];

export default function ProductFormMobile({
  delivery,
  setFormOpen,
  open,
  products,
  enrollment,
  setUpdatedEnrollment,
}) {
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleClose = () => {
    setFormOpen(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const res = await updateEnrollmentRequest({
        product_deliveries_attributes: [
          {
            id: delivery.id,
            product_id: data.product_id,
            delivered_at: data.delivered_at,
            quantity: data.quantity,
            price: data.price || delivery.product.default_price,
            visitor_name: data.visitor_name,
            delivery_type: data.delivery_type,
            _destroy: data === "destroy",
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        handleClose();
        setUpdatedEnrollment(res.data);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <PageLoader className="h-[200px]" variant="primary" />
  ) : (
    <div
      className={twMerge(
        "transform transition-all duration-300 ease-in-out origin-top",
        open ? "h-auto opacity-100 scale-y-100" : "h-0 opacity-0 scale-y-0"
      )}
    >
      {open && (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="p-4 flex flex-col gap-4 border-t border-gray-500/10 mt-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Select
              id="product_id"
              label="Product Offer"
              defaultValue={delivery?.product.id}
              isRequired
            >
              <SelectItem value="" disabled>
                Select a product
              </SelectItem>
              {products?.map((product) => (
                <SelectItem key={product.id} value={product.id}>
                  {product.name}
                </SelectItem>
              ))}
            </Select>

            <DatePickerInput
              id="delivered_at"
              label="Delivered At"
              selected={delivery?.delivered_at || new Date()}
            />

            <Input
              id="quantity"
              label="Quantity"
              type="number"
              defaultValue={delivery?.quantity}
            />

            <Input
              id="price"
              label="Price"
              type="number"
              size="12"
              min="0.0"
              step="0.01"
              defaultValue={delivery?.price}
            />

            <Select
              id="delivery_type"
              label="Delivery Type"
              defaultValue={delivery?.delivery_type}
            >
              <SelectItem value="" disabled selected>
                Select a delivery type
              </SelectItem>
              {deliveryTypes.map((deliveryType) => (
                <SelectItem key={deliveryType.label} value={deliveryType.value}>
                  {deliveryType.value}
                </SelectItem>
              ))}
            </Select>

            <Input
              id="visitor_name"
              label="Visitor"
              defaultValue={delivery?.visitor_name}
            />

            <div className="flex gap-4 justify-center">
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>

            <div className="flex mt-4 justify-center">
              <button
                type="button"
                className="text-red-600 flex items-center gap-1"
                onClick={() => setDeleteModalOpen(true)}
              >
                <TrashIcon className="w-5" />
                Delete
              </button>
            </div>
          </form>
        </FormProvider>
      )}

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => onSubmit("destroy")}
        />
      </Modal>
    </div>
  );
}
