import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../../../../../../UI/Inputs/Input";
import InputGroup from "../../../../../../UI/Inputs/InputGroup";
import Button from "../../../../../../UI/Button";
import Select from "../../../../../../UI/Inputs/Select/Select";
import SelectItem from "../../../../../../UI/Inputs/Select/SelectItem";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function Notes({ notable }) {
  const { setValue, getValues } = useFormContext();
  const [notes, setNotes] = useState([]);

  const initialNotes = () => {
    return notable?.notes || [];
  };

  const noteTypes = [
    { name: "Note", value: "Note" },
    { name: "Prayer Request", value: "PrayerRequest" },
  ];

  useEffect(() => {
    const initialNotesData = initialNotes();
    setNotes(initialNotesData);
    setValue("notable_notes_attributes", initialNotesData);
  }, [notable, setValue]);

  return (
    <InputGroup col>
      <div className="space-y-4 mt-6">
        <h3 className="font-bold mb-3">NOTES & PRAYER REQUESTS</h3>

        {notes.map(
          (note, index) =>
            !note._destroy && (
              <div key={index} className="flex justify-between gap-2">
                <div className="flex-1">
                  <Select
                    id={`notable_notes_attributes.${index}.type`}
                    defaultValue={note.note_type}
                    placeholder="Select type"
                    classNames="mb-2"
                  >
                    {noteTypes.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Input
                    id={`notable_notes_attributes.${index}.text`}
                    rows={2}
                    placeholder="Enter note..."
                    defaultValue={note.text}
                  />
                </div>
                {note.id && (
                  <>
                    <input
                      type="hidden"
                      id={`notable_notes_attributes.${index}.id`}
                      defaultValue={note.id}
                    />
                    <input
                      type="hidden"
                      id={`notable_notes_attributes.${index}._destroy`}
                      defaultValue={note._destroy || false}
                    />
                  </>
                )}
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    const currentNotes = [...notes];
                    if (note.id) {
                      // If note has an ID, mark for destruction
                      setValue(
                        `notable_notes_attributes.${index}._destroy`,
                        true
                      );
                      currentNotes[index] = { ...note, _destroy: true };
                    } else {
                      // If note has no ID, remove it from the array
                      const formNotes = getValues("notable_notes_attributes");
                      formNotes.splice(index, 1);
                      setValue("notable_notes_attributes", formNotes);
                      currentNotes.splice(index, 1);
                    }
                    setNotes(currentNotes);
                  }}
                >
                  <TrashIcon className="h-5 w-5" />
                </Button>
              </div>
            )
        )}

        <div className="flex">
          <Button
            type="button"
            variant="primary-outline"
            icon
            onClick={() => {
              const currentNotes = getValues("notable_notes_attributes") || [];
              setValue("notable_notes_attributes", [
                ...currentNotes,
                { text: "", type: "", _destroy: false },
              ]);
              setNotes([...notes, { text: "", type: "", _destroy: false }]);
            }}
          >
            <PlusIcon className="h-5 w-5" />
            Add Note
          </Button>
        </div>
      </div>
    </InputGroup>
  );
}
