import React, { useEffect, useState } from "react";
import ActionMenuContainer from "../../../ActionMenu/ActionMenuContainer";
import { FormProvider, useForm } from "react-hook-form";
import Input from "../../../UI/Inputs/Input";
import Button from "../../../UI/Button";
import { addSchoolRequest } from "../../../../requests/seminars";
import { ToastNotification } from "../../../UI/ToastNotification";
import { getAllSchools } from "../../../../requests/schools";
import { checkPermission } from "../../../../utils/checkPermission";
import { useNavigate, useParams } from "react-router-dom";
import { displayError } from "../../../../utils/displayError";
import SearchSchool from "../../../UI/SearchSchool";

export default function NewSchool({ closeModal, seminar }) {
  const methods = useForm();
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const params = useParams();
  const seminarId = params.seminarId;
  const [schools, setSchool] = useState([]);
  const navigate = useNavigate();

  const getSchools = async () => {
    const res = await getAllSchools({ params: { pagination: false } });
    if (res.data) {
      setSchool(res.data.schools);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      getSchools();
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      const res = await addSchoolRequest({
        ...data,
        school_id: data.school?.value,
        series_id: seminarId,
      });

      if (res.data) {
        ToastNotification("success", "School added successfully.");
        closeModal();
        navigate(`/seminars/${res.data.series_id}/details`);
      }
    } catch (e) {
      displayError(e);
    }
  };

  function checkIsExisting(school_id, seminar) {
    let disabled = school_id === seminar.host_school_id;
    if (!disabled) {
      disabled = seminar.schools.some((school) => school.id === school_id);
    }
    return disabled;
  }

  return (
    <div className="bg-neutral-600 relative min-h-[500px]">
      <ActionMenuContainer label="Add School" clickToClose={closeModal}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="md:flex-row md:mb-6 flex justify-between flex-col max-w-[700px] mx-auto">
              <div className="md:w-4/6 md:mr-8">
                <SearchSchool
                  id="school"
                  label="Select School"
                  options={(schools || [])
                    .filter((school) => !checkIsExisting(school.id, seminar))
                    .map((school) => ({
                      value: school.id,
                      label: school.display_name,
                    }))}
                  isRequired
                />
              </div>
              <div className="md:w-2/6 flex items-center mb-2 mt-4 md:mt-0">
                <Input
                  isRequired
                  id="team_number"
                  label="Team Number"
                  dark
                  className="w-full"
                />
              </div>
            </div>

            <div className="md:flex-row flex justify-center mt-4 md:mt-0">
              <div className="text-center md:mr-4">
                <Button type="submit" variant="primary">
                  Add School
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </ActionMenuContainer>
    </div>
  );
}
