import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import handleLogout from "../../../utils/logout";

export default function ProfileMenu({ userNavigation, userData }) {
  return (
    <Menu as="div" className="relative ml-7 inline-block text-left">
      <Menu.Button className="bg-background relative flex h-10 w-10 rounded-full text-sm focus:outline-none">
        {userData?.profile_picture_url ? (
          <img
            className="h-full w-full rounded-full"
            src={userData?.profile_picture_url}
            alt="Profile Picture"
          />
        ) : (
          <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-300 text-gray-600 font-semibold">
            {(userData?.first_name || "A").charAt(0).toUpperCase()}
          </div>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account/my-account"
                  className={twMerge(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  My Account
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            {userNavigation.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <Link
                    to={item.href}
                    className={twMerge(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block cursor-pointer px-4 py-2 text-sm"
                    )}
                  >
                    {item.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <p
                  onClick={handleLogout}
                  className={twMerge(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block cursor-pointer px-4 py-2 text-sm"
                  )}
                >
                  Logout
                </p>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
