import React from "react";
import { useRouteLoaderData } from "react-router-dom";
import CardLink from "../../../../UI/Card/CardLink";
import PageLoader from "../../../../UI/PageLoader";
import { twMerge } from "tailwind-merge";

export default function StudentMobileSeminars({
  seminars,
  returnStudentEnrollment,
  handleEnrollment,
}) {
  const student = useRouteLoaderData("student").data;

  if (!seminars) return <PageLoader className="h-[50vh]" />;

  // Transform courses into enhanced objects with enrollment and graduation status
  const sortedSeminars = seminars
    .map((seminar) => ({
      ...seminar,
      enrollment: returnStudentEnrollment(seminar.id),
      completed: returnStudentEnrollment(seminar.id)?.deliveries_complete,
    }))
    .sort((a, b) => {
      // Get priority
      // 0: enrolled, 1: unenrolled, 2: completed
      const getPriority = (seminar) => {
        if (seminar.completed) return 2;
        if (!seminar.enrollment) return 1;
        return 0;
      };

      return getPriority(a) - getPriority(b);
    });

  const daysPast = (enrollment) => {
    const dateSent =
      enrollment.cached_latest_received_date || enrollment.created_at;

    const days = Math.floor(
      (new Date() - new Date(dateSent)) / (1000 * 60 * 60 * 24)
    );

    return days > 0 ? days : 0;
  };

  return sortedSeminars?.length ? (
    <div>
      {sortedSeminars.map((seminar) => (
        <CardLink
          key={seminar.id}
          to={
            seminar.enrollment &&
            `/students/${student?.id}/seminars/enrollments/${seminar.enrollment.id}/presentations`
          }
          className="mb-4 last:mb-10 block"
          cardClassName={twMerge(
            "rounded-2xl p-4",
            (seminar.completed || !seminar.enrollment) && "py-6"
          )}
          dark={seminar.completed}
        >
          <span className="flex justify-between items-center gap-2">
            <span className="text-[17px] leading-tight">{seminar.name}</span>
            {seminar.completed ? (
              <span className="text-center text-xs">
                <span className="block whitespace-nowrap">Completed</span>
                <span className="block whitespace-nowrap">
                  {Intl.DateTimeFormat("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    timeZone: "UTC",
                  }).format(
                    new Date(seminar.enrollment.cached_latest_received_date)
                  )}
                </span>
              </span>
            ) : seminar.enrollment ? (
              <span className="flex text-center gap-4">
                <span>
                  <span className="block text-xs whitespace-nowrap">
                    Presentation
                  </span>
                  <span className="block text-[40px] leading-none">
                    {seminar.enrollment?.cached_attended_count || 0 + 1}
                  </span>
                </span>
                <span className="flex gap-2">
                  <span>
                    <span className="block text-xs whitespace-nowrap">
                      Days Past
                    </span>
                    <span className="block text-[40px] leading-none">
                      {daysPast(seminar.enrollment)}
                    </span>
                  </span>
                  <span className="hidden w-4">
                    <span className="rounded-full bg-[#35CDBB] px-2 py-1 text-xs">
                      {1}
                    </span>
                  </span>
                </span>
              </span>
            ) : (
              <button
                className="text-primary text-xs border border-primary rounded-md px-2 py-1"
                onClick={() => handleEnrollment(seminar)}
              >
                Enroll
              </button>
            )}
          </span>
        </CardLink>
      ))}
    </div>
  ) : (
    <div className="text-center py-10 text-gray-500">No seminars found.</div>
  );
}
