import React, { useEffect, useState } from "react";
import {
  Link,
  useOutletContext,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import { useFilterValues } from "../../../UI/Filter/Filter";
import PageLoader from "../../../UI/PageLoader";
import Card from "../../../UI/Card";
import ListButtonGroup from "../../../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../../../UI/ListButtonGroup/ListButtonGroupItem";
import QuickFind from "../../../UI/QuickFind";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import Button from "../../../UI/Button";
import { getAllUserStudentsRequest } from "../../../../requests/users";
import { fullName } from "../../../../utils/fullName";
import Modal from "../../../UI/Modal";
import PrintTicket from "../../../Students/Student/PrintTicket";
import EmptyTable from "../../../UI/EmptyTable";
import { hoverLink } from "../../../../utils/hoverLink";
import dayjs from "dayjs";
import { returnEnrollmentStatus } from "../../../../utils/enrollmentStatus";
import { checkPermission } from "../../../../utils/checkPermission";
import InstructorStudentsMobile from "./Mobile/InstructorStudentsMobile";

export default function InstructorStudents() {
  const isMobile = useMediaQuery("md", "down");
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const filterValues = useFilterValues();
  const { setActionMenuModal } = useOutletContext();
  const instructor = useRouteLoaderData("user").data;
  const [loading, setLoading] = useState(false);
  const [printTicketModal, setPrintTicketModal] = useState({
    open: false,
    student: null,
  });

  const tableNavigation = [
    {
      label: "Active (#)",
    },
    {
      label: "Inactive (#)",
    },
  ];

  const [students, setStudents] = useState();

  const getStudents = async () => {
    setLoading(true);

    const params = { q: filterValues };

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      const attributes = [
        "full_name",
        "enrollments_registration_number",
        "school_church_name",
        "school_school_code",
      ];
      params.q[`${attributes.join("_or_")}_i_cont_all`] = query
        .trim()
        .split(" ");
    }

    const res = await getAllUserStudentsRequest({
      userId: instructor.id,
      params,
    });

    setStudents(res.data);
    setLoading(false);
  };

  const seriesTypeLink = (studentId, enrollment) => {
    switch (enrollment.series_type) {
      case "Course":
        return `/students/${studentId}/courses/enrollments/${enrollment.id}/lessons`;
      case "Seminar":
        return `/students/${studentId}/seminars/enrollments/${enrollment.id}/presentations`;
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("query", data.search || "");
      return prev;
    });
  };

  useEffect(() => {
    getStudents();
  }, [searchParams]);

  if (!students && !isMobile)
    return (
      <Card classNames="p-6 lg:mt-10">
        <PageLoader />
      </Card>
    );

  return isMobile ? (
    <InstructorStudentsMobile students={students} />
  ) : (
    <>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["school_admin", "conference_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() =>
                  setActionMenuModal({
                    open: true,
                    defaultRender: "NewStudent",
                  })
                }
              />
            )}

            <h1 className="text-xl font-bold text-primary">STUDENTS</h1>
          </div>
          <ListButtonGroup>
            {tableNavigation.map((item, index) => (
              <ListButtonGroupItem
                key={item.label}
                label={item.label}
                isFirst={index === 0}
                isLast={index === tableNavigation.length - 1}
              />
            ))}
          </ListButtonGroup>
          <div className="flex">
            <QuickFind onSubmit={onSubmit} />
          </div>
        </div>
        {students?.length ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>STUDENT</TableHeader>
                <TableHeader>ENROLLMENTS</TableHeader>
                <TableHeader>CREATED</TableHeader>
                <TableHeader>SCHOOL</TableHeader>
                <TableHeader></TableHeader>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr
                  key={student.id}
                  className={loading ? "opacity-25" : undefined}
                >
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/students/${student.id}/overview`}
                    >
                      {fullName(student.first_name, " ", student.last_name)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {student.enrollments.map((enrollment) => (
                      <div key={enrollment.id}>
                        <Link
                          to={seriesTypeLink(student.id, enrollment)}
                          className={hoverLink}
                        >
                          {enrollment.series_name}
                        </Link>{" "}
                        -{" "}
                        {returnEnrollmentStatus(
                          enrollment.series_id,
                          enrollment
                        )}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {dayjs(student.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/schools/${student.school?.id}/overview`}
                    >
                      {student.school?.school_name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        setPrintTicketModal({ open: true, student: student })
                      }
                      variant="primary-outline"
                    >
                      Print Ticket
                    </Button>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No students found for this instructor." />
        )}
      </Card>

      <Modal
        open={printTicketModal.open}
        setOpen={() => setPrintTicketModal({ open: false, student: null })}
      >
        <PrintTicket
          student={printTicketModal.student}
          closeModal={() => setPrintTicketModal({ open: false, student: null })}
        />
      </Modal>
    </>
  );
}
