import { logout } from "../requests/auth";
import { removeToken } from "./auth";
import { ToastNotification } from "../components/UI/ToastNotification";

const handleLogout = async () => {
  try {
    const res = await logout();

    if (
      res.data.status === 200 &&
      res.data.message === "Logged out successfully."
    ) {
      removeToken();
      window.location.replace("/login");
    }
  } catch (e) {
    const errorMessage = e.response?.data?.error;
    ToastNotification("error", errorMessage);
  }
};

export default handleLogout;
