import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import PageLoader from "../../../../../../UI/PageLoader";
import CardLink from "../../../../../../UI/Card/CardLink";
import PresentationForm from "./PresentationForm";

export default function PresentationsMobile({
  presentations,
  enrollment,
  user,
  decisionOptions,
}) {
  if (!presentations) return <PageLoader className="h-[60vh]" />;

  const [formOpen, setFormOpen] = useState();
  const [updatedEnrollment, setUpdatedEnrollment] = useState(enrollment);

  // Merge student enrollments into courses and sort it so that
  // graduated courses are at the bottom.
  let sortedPresentations = presentations
    .map((presentation) => ({
      ...presentation,
      episodeDelivery: updatedEnrollment.episode_deliveries.find(
        (item) => item.episode_id === presentation.id
      ),
    }))
    .map((presentation) => ({
      ...presentation,
      attended: Boolean(presentation.episodeDelivery?.date_completed),
      sent: Boolean(presentation.episodeDelivery?.date_sent),
    }))
    .sort((a, b) => Number(Boolean(a.attended)) - Number(Boolean(b.attended)));

  return sortedPresentations.length ? (
    sortedPresentations.map((presentation) => (
      <CardLink
        key={presentation.id}
        className="mb-4 last:mb-10 block"
        cardClassName={twMerge(
          "rounded-2xl p-0 py-2",
          presentation.attended && "p-0 rounded-2xl",
          formOpen === presentation.id && "z-10"
        )}
        onClick={() => {
          setFormOpen(presentation.id);
        }}
        activeEffect={formOpen !== presentation.id}
        dark={presentation.attended && formOpen !== presentation.id}
      >
        {!presentation.attended ? (
          <>
            <span
              className={twMerge(
                "block py-2 px-4 text-lg leading-none",
                presentation.sent && "border-b border-gray-500/10"
              )}
            >
              {presentation.name}
            </span>
            {presentation.sent && (
              <span className="flex items-end justify-between pt-2 px-4">
                <span>
                  <span className="block">
                    Sent - {presentation.episodeDelivery?.delivery_type}
                  </span>
                  <span className="block">
                    {Intl.DateTimeFormat("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      timeZone: "UTC",
                    }).format(
                      new Date(presentation.episodeDelivery.date_completed)
                    )}
                  </span>
                </span>
                <span className="text-center">
                  <span className="block text-xs whitespace-nowrap">
                    Days Past
                  </span>
                  <span className="block text-[40px] leading-none">
                    {Math.floor(
                      (new Date() -
                        new Date(presentation.episodeDelivery.date_completed)) /
                        (1000 * 60 * 60 * 24)
                    )}
                  </span>
                </span>
              </span>
            )}
          </>
        ) : (
          <span
            className={twMerge(
              "p-6 flex justify-between items-center gap-4 text-white",
              formOpen === presentation.id && "text-black"
            )}
          >
            <span className="block text-lg leading-none">
              {presentation.name}
            </span>
            <span className="text-center text-xs">
              <span className="block whitespace-nowrap">Attended</span>
              <span className="block whitespace-nowrap">
                {Intl.DateTimeFormat("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  timeZone: "UTC",
                }).format(
                  new Date(presentation.episodeDelivery?.date_completed)
                )}
              </span>
            </span>
          </span>
        )}

        <PresentationForm
          open={formOpen === presentation.id}
          enrollment={enrollment}
          presentation={presentation}
          episodeDelivery={presentation.episodeDelivery}
          user={user}
          setFormOpen={setFormOpen}
          setUpdatedEnrollment={setUpdatedEnrollment}
          decisionOptions={decisionOptions}
        />
      </CardLink>
    ))
  ) : (
    <div className="text-center py-10 text-gray-500">
      No presentations found for this seminar.
    </div>
  );
}
