import React, { useEffect, useState } from "react";
import {
  XMarkIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowRightStartOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useMobileNavigation } from "../../../contexts/MobileNavigationContext";
import handleLogout from "../../../utils/logout";

export default function MobileNavigation({
  baseNavigation,
  setMobileMenuOpen,
}) {
  const { mobileNavigation, thirdMobileNavigation } = useMobileNavigation();
  const level = thirdMobileNavigation ? 3 : mobileNavigation ? 2 : 1;
  const title = thirdMobileNavigation?.title || mobileNavigation?.title;

  const subtitle =
    thirdMobileNavigation?.subtitle || mobileNavigation?.subtitle;

  const links =
    thirdMobileNavigation?.links || mobileNavigation?.links || baseNavigation;

  const [navigation, setNavigation] = useState({
    level,
    title,
    subtitle,
    links,
  });

  const handlePreviousLevel = () => {
    setNavigation((prev) => {
      switch (prev.level) {
        case 3:
          return {
            level: 2,
            title: mobileNavigation?.title,
            subtitle: mobileNavigation?.subtitle,
            links: mobileNavigation.links,
          };
        case 2:
          return {
            level: 1,
            title: null,
            subtitle: null,
            links: baseNavigation,
          };
      }
    });
  };

  const handleNextLevel = () => {
    setNavigation((prev) => {
      switch (prev.level) {
        case 1:
          return {
            level: 2,
            title: mobileNavigation?.title,
            subtitle: mobileNavigation?.subtitle,
            links: mobileNavigation.links,
          };
        case 2:
          return {
            level: 3,
            title: thirdMobileNavigation?.title,
            subtitle: thirdMobileNavigation?.subtitle,
            links: thirdMobileNavigation.links,
          };
      }
    });
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <div className="h-[10%] w-full flex items-center justify-between p-4">
        <div className="flex items-center gap-2">
          {navigation.level <= level && navigation.level > 1 && (
            <button type="button" onClick={handlePreviousLevel}>
              <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          )}

          {navigation.level < level && (
            <button type="button" onClick={handleNextLevel}>
              <ArrowRightIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          )}
        </div>

        <button type="button" onClick={() => setMobileMenuOpen(false)}>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <div className="h-[75%] w-full overflow-y-auto px-8">
        {navigation.title && (
          <div className="uppercase text-[#35CDBB] text-[24px] pb-2 leading-none">
            {navigation.title}
          </div>
        )}

        {navigation.subtitle && (
          <div className="uppercase text-[#35CDBB] text-[18px] pb-2 leading-none">
            {navigation.subtitle}
          </div>
        )}

        {navigation.links?.map((item) => (
          <Link
            onClick={() => setMobileMenuOpen(false)}
            key={item.name}
            to={item.href}
            className="block py-2 text-[32px]"
            aria-current={item.current ? "page" : undefined}
          >
            {item.name}
          </Link>
        ))}
      </div>

      <div className="h-[15%] w-full flex items-start pt-4 justify-center">
        <button type="button" onClick={handleLogout}>
          <span className="flex items-center gap-2">
            <ArrowRightStartOnRectangleIcon className="h-8 w-8" />
            Log Out
          </span>
        </button>
      </div>
    </>
  );
}
