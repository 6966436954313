import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import PageLoader from "../../../../../../UI/PageLoader";
import CardLink from "../../../../../../UI/Card/CardLink";
import QuestionForm from "./QuestionFormMobile";

export default function QuestionsMobile({
  questions,
  responses,
  enrollment,
  reloadRecords,
}) {
  if (!questions || !responses) return <PageLoader className="h-[60vh]" />;

  const [formOpen, setFormOpen] = useState();

  const mergedQuestions = questions.map((question) => ({
    ...question,
    response: responses?.find(
      (response) => response.question_id === question.id
    ),
  }));

  return mergedQuestions?.length ? (
    mergedQuestions.map((question) => (
      <CardLink
        key={question.id}
        className="mb-4 last:mb-10 block"
        cardClassName={twMerge(
          "rounded-2xl p-0 py-4",
          formOpen === question.id && "z-10"
        )}
        onClick={() => {
          setFormOpen(question.id);
        }}
        activeEffect={formOpen !== question.id}
        dark={Boolean(question.response) && formOpen !== question.id}
      >
        <span className="block text-lg px-4 leading-tight">
          {question.text}
        </span>

        <span className="block text-sm px-4 leading-tight">
          {question.episode.name}
        </span>

        {formOpen !== question.id && (
          <span className="block text-sm px-4 leading-tight mt-2">
            R: {question.response?.response}
          </span>
        )}

        <QuestionForm
          open={formOpen === question.id}
          question={question}
          enrollment={enrollment}
          setFormOpen={setFormOpen}
          reloadRecords={reloadRecords}
        />
      </CardLink>
    ))
  ) : (
    <div className="text-center py-10 text-gray-500">
      No questions found for this course.
    </div>
  );
}
