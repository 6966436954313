import React, { useEffect } from "react";
import Select from "../../UI/Inputs/Select/Select";
import SelectItem from "../../UI/Inputs/Select/SelectItem";

export default function SeriesSelect({ student, methods }) {
  const selectedSeries = methods.watch("series_id");

  useEffect(() => {
    if (student) {
      const selectedEnrollmentId = [
        ...student.course_enrollments,
        ...student.seminar_enrollments,
      ].find((enrollment) => enrollment.series_id === selectedSeries)?.id;

      methods.setValue("selected_enrollment_id", selectedEnrollmentId);
    }
  }, [student, selectedSeries]);

  return (
    <Select
      id="series_id"
      classNames="w-full bg-transparent"
      label="Course / Seminar"
      isRequired
    >
      <SelectItem value="" disabled>
        Select
      </SelectItem>
      {student?.course_enrollments?.length > 0 && (
        <optgroup label="Courses">
          {student?.course_enrollments?.map((enrollment) => (
            <SelectItem key={enrollment.id} value={enrollment.series_id}>
              {enrollment.series_name}
            </SelectItem>
          ))}
        </optgroup>
      )}
      {student?.seminar_enrollments?.length > 0 && (
        <optgroup label="Seminars">
          {student?.seminar_enrollments?.map((enrollment) => (
            <SelectItem key={enrollment.id} value={enrollment.series_id}>
              {enrollment.series_name}
            </SelectItem>
          ))}
        </optgroup>
      )}
    </Select>
  );
}
