import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ToastNotification } from "../../../../../../UI/ToastNotification";
import DatePickerInput from "../../../../../../UI/Inputs/DatePickerInput";
import MultiSelect from "../../../../../../UI/Inputs/MultiSelect";
import Button from "../../../../../../UI/Button";
import PageLoader from "../../../../../../UI/PageLoader";
import { updateEnrollmentRequest } from "../../../../../../../requests/enrollments";
import Notes from "../../Notable/Mobile/Notes";
import Modal from "../../../../../../UI/Modal";
import ConfirmationDialog from "../../../../../../UI/ConfirmationDialog";

export default function VisitForm({
  enrollment,
  visit,
  setFormOpen,
  setUpdatedEnrollment,
  open,
  decisionOptions,
}) {
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const selectedDecisionOptions = decisionOptions.filter((option) =>
    visit?.decisions.some(
      (decision) => decision.decision_option_id === option.id
    )
  );

  const handleClose = () => {
    setFormOpen(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const decisions = decisionOptions.map((decision_option) => ({
        id: visit?.decisions.find(
          (decision) => decision.decision_option_id === decision_option.id
        )?.id,
        decision_option_id: decision_option.id,
        cached_enrollment_id: enrollment.id,
        _destroy: data.decisions?.find(
          (decision) => decision.id === decision_option.id
        )
          ? false
          : true,
      }));

      const notes = data.notable_notes_attributes?.filter(
        (note) => note.text.trim() !== "" && note.type.trim() !== ""
      );

      const res = await updateEnrollmentRequest({
        visits_attributes: [
          {
            id: visit.id,
            date: data.date,
            notes_attributes: notes,
            decisions_attributes: decisions,
            _destroy: data === "destroy",
          },
        ],
        enrollmentId: enrollment.id,
      });

      if (res.data) {
        ToastNotification("success", "Updated successfully.");
        handleClose();
        setUpdatedEnrollment(res.data);
      }
    } catch (e) {
      ToastNotification("error", e.message);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <PageLoader className="h-[200px]" variant="primary" />
  ) : (
    <div
      className={twMerge(
        "transform transition-all duration-300 ease-in-out origin-top",
        open ? "h-auto opacity-100 scale-y-100" : "h-0 opacity-0 scale-y-0"
      )}
    >
      {open && (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="p-4 flex flex-col gap-4 border-t border-gray-500/10 mt-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div>
              <label htmlFor="date">Date</label>
              <DatePickerInput id="date" selected={visit.date} />
            </div>

            <Notes notable={visit} />

            <h3 className="font-bold">DECISIONS</h3>

            <MultiSelect
              id="decisions"
              placeholder="Add Decision"
              options={decisionOptions}
              defaultSelected={selectedDecisionOptions}
            />

            <div className="flex gap-4 justify-center">
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Save
              </Button>
            </div>

            <div className="flex mt-4 justify-center">
              <button
                type="button"
                className="text-red-600 flex items-center gap-1"
                onClick={() => setDeleteModalOpen(true)}
              >
                <TrashIcon className="w-5" />
                Delete
              </button>
            </div>
          </form>
        </FormProvider>
      )}

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => onSubmit("destroy")}
        />
      </Modal>
    </div>
  );
}
