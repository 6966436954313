import React, { useEffect, useState } from "react";
import Button from "../../../UI/Button";
import { XMarkIcon } from "@heroicons/react/20/solid";
import Pill from "../../../UI/Pill";
import { displayError } from "../../../../utils/displayError";
import { ToastNotification } from "../../../UI/ToastNotification";
import { updateSchoolClaimedZipCodes } from "../../../../requests/schools";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import Modal from "../../../UI/Modal";

export default function ClaimedZipCodes({ closeModal, postalCodes }) {
  const [claimedZipCodes, setClaimedZipCodes] = useState(
    postalCodes.map(({ zip }) => zip)
  );
  const params = useParams();
  const schoolId = params.schoolId;
  const navigate = useNavigate();
  const [postalCode, setPostalCode] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleAdd = async () => {
    try {
      if (postalCode.includes("-")) {
        const range = postalCode.split("-");
        const start = parseInt(range[0]);
        const end = parseInt(range[1]);
        let current = start;
        let rangeArray = [];

        if (current.toString().length !== 5 || end.toString().length !== 5) {
          ToastNotification("error", "Please enter correct range.");
          return;
        }

        while (current <= end) {
          if (!claimedZipCodes.includes(current.toString())) {
            rangeArray.push(current.toString());
          }
          current += 1;
        }

        setClaimedZipCodes([...claimedZipCodes, ...rangeArray]);
      } else if (postalCode.includes("*")) {
        const prefix = postalCode.split("*")[0];
        let patternArray = [];

        if (prefix.length !== 3) {
          ToastNotification("error", "Please enter correct pattern.");
          return;
        }

        [...Array(100).keys()].forEach((pa) => {
          pa = ("0" + pa).slice(-2);
          const actualArray = prefix + pa;
          if (!claimedZipCodes.includes(actualArray)) {
            patternArray.push(actualArray);
          }
        });

        setClaimedZipCodes([...claimedZipCodes, ...patternArray]);
      } else {
        if (postalCode.length !== 5) {
          ToastNotification("error", "Please enter correct zipcode.");
          return;
        }

        if (!claimedZipCodes.includes(postalCode)) {
          setClaimedZipCodes([...claimedZipCodes, postalCode]);
        }
      }

      document.getElementById("postal_code").value = null;
      setPostalCode("");
      document.getElementById("postal_code").focus();
    } catch (e) {
      displayError(e);
    }
  };

  const handleDelete = async (code) => {
    setClaimedZipCodes(claimedZipCodes.filter((item) => item !== code));
  };

  const handleDeleteAllModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const handleDeleteAll = async () => {
    setClaimedZipCodes([]);
    setDeleteModalOpen(false);
  };

  const handleSave = async () => {
    try {
      const res = await updateSchoolClaimedZipCodes({
        data: claimedZipCodes,
        schoolId: schoolId,
      });

      if (res.data) {
        ToastNotification("success", "Claimed zip codes updated successfully.");
        closeModal();
        navigate(".", { replace: true });
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    document.getElementById("postal_code").focus();
  }, []);

  return (
    <div className="md:w-[700px]">
      <div className="p-[30px] bg-[#F5F5F5]">
        <div className="flex float-right">
          <Button
            variant="primary-outline"
            classNames="mr-4"
            className="mr-6"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleSave()}>
            Save
          </Button>
        </div>
        <div className="pt-[50px]">
          <p className="mb-2">Enter Postal Code</p>
          <div className="flex">
            <input
              id="postal_code"
              className="w-1/2 mr-4 rounded border px-2 py-1.5 h-[38px]"
              onInput={(e) => setPostalCode(e.target.value)}
              defaultValue={postalCode}
            />
            <Button
              variant="primary"
              onClick={() => handleAdd()}
              disabled={!postalCode}
            >
              Add Postal Code
            </Button>
          </div>
          <p className="mt-2">
            Enter as: Individual zip code, range of zip codes <br /> (e.g:
            90900-90910, or zip pattern matching anything after the asterisk
            (e.g.: 997*)
          </p>
        </div>
      </div>

      <div className="flex flex-wrap justify-center md:justify-start p-4 md:p-[30px]">
        {claimedZipCodes.map((code) => (
          <Pill
            key={code}
            classNames="bg-[#e1f8fb] text-black mr-2 mb-2 p-2 rounded min-w-[80px]"
          >
            {code}
            <div className="cursor-pointer bg-[#e1f8fb] w-4 border border-black rounded-full ml-1">
              <XMarkIcon onClick={() => handleDelete(code)} />
            </div>
          </Pill>
        ))}
      </div>

      {claimedZipCodes.length > 0 && (
        <div className="pb-[30px] text-center px-4 md:px-0">
          <Button
            classNames="md:w-auto w-full mr-4"
            variant="secondary"
            onClick={(e) => handleDeleteAllModal(e)}
          >
            Remove All
          </Button>
        </div>
      )}

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => handleDeleteAll()}
        />
      </Modal>
    </div>
  );
}
