import React from "react";
import { twMerge } from "tailwind-merge";
import { fullName } from "../../../../../utils/fullName";
import CardLink from "../../../../UI/Card/CardLink";
import PageLoader from "../../../../UI/PageLoader";
import StudentEnrollmentsMobile from "../../../../UI/StudentEnrollmentsMobile";

export default function InstructorStudentsMobile({ students }) {
  if (!students) return <PageLoader className="h-[50vh]" />;

  return students.length ? (
    students.map((student) => (
      <CardLink
        key={student.id}
        to={`/students/${student.id}/overview`}
        className="mb-4 last:mb-0 block"
        cardClassName="rounded-xl p-0 pb-2"
      >
        <span
          className={twMerge(
            "flex p-2 justify-between",
            student.enrollments.length > 0 && "border-b border-gray-500/10"
          )}
        >
          <span className="text-lg p-2 pb-0">
            {fullName(student.first_name, " ", student.last_name)}
          </span>
        </span>

        <StudentEnrollmentsMobile student={student} />
      </CardLink>
    ))
  ) : (
    <div className="text-center py-10 text-gray-500">No students found.</div>
  );
}
