import React, { useEffect, useState } from "react";
import { Pagination, usePagination } from "../UI/Pagination";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Card from "../UI/Card";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import QuickFind from "../UI/QuickFind";
import CoursesMobileList from "./Mobile/CoursesMobileList";
import { getAllCourses } from "../../requests/courses";
import { checkPermission } from "../../utils/checkPermission";
import EmptyTable from "../UI/EmptyTable";
import { hoverLink } from "../../utils/hoverLink";
import { displayError } from "../../utils/displayError";
import { highlightText } from "../../utils/highlightText";

export default function Courses() {
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const isGlobalAdmin = checkPermission("global_admin");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const page = searchParams.get("page") || "1";
  const [loading, setLoading] = useState(false);
  const pagination = usePagination();
  const [courses, setCourses] = useState();

  const getCourses = async () => {
    setLoading(true);

    try {
      const queryParams = {
        page: page,
        per_page: pagination.recordsPerPage,
        q: {},
      };

      // Only add the search parameter if query is not empty.
      // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
      // Splitting the query terms into an array for the `cont` matcher.
      if (query.trim()) {
        queryParams.q.name_i_cont_all = query.trim().split(" ");
      }

      const res = await getAllCourses({ params: queryParams });

      if (res.data) {
        setCourses(res.data.courses);
        pagination.setData(res.data.pagination);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  // Fetch courses when the component mounts
  // and when query params change.
  useEffect(() => {
    getCourses();
  }, [searchParams, pagination.recordsPerPage]);

  if (!isAdmin) {
    return (
      <EmptyTable message="You do not have permission to view this page" />
    );
  }

  return (
    <div>
      <div className="md:hidden">
        <CoursesMobileList
          courses={courses}
          isGlobalAdmin={isGlobalAdmin}
          loading={loading}
        />

        <Pagination
          pagination={pagination}
          loading={loading && !courses?.length}
          empty={!courses?.length}
        />
      </div>
      <div className="hidden md:block">
        <Card classNames="lg:mt-10">
          <div className="flex items-center justify-between mb-6">
            <div className="relative">
              {isGlobalAdmin && (
                <img
                  src="/add-new.svg"
                  className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                  width={30}
                  height={30}
                  alt="Add New"
                  onClick={() => navigate("/courses/new")}
                />
              )}

              <h1 className="text-xl font-bold text-primary">COURSES</h1>
            </div>
            <div className="flex">
              <QuickFind onSubmit={onSubmit} value={query} />
            </div>
          </div>
          <Pagination
            pagination={pagination}
            loading={loading && !courses?.length}
            empty={Boolean(!courses?.length)}
          >
            <Table>
              <thead>
                <tr>
                  <TableHeader>COURSE</TableHeader>
                  {isGlobalAdmin && <TableHeader>LESSONS</TableHeader>}
                  {isGlobalAdmin && <TableHeader>SCHOOLS</TableHeader>}
                  <TableHeader>ACTIVE STUDENTS</TableHeader>
                  <TableHeader>TOTAL STUDENTS</TableHeader>
                  {isGlobalAdmin && <TableHeader>PRODUCTS</TableHeader>}
                </tr>
              </thead>
              <tbody>
                {courses?.map((course) => (
                  <tr
                    key={course.id}
                    className={loading ? "opacity-25" : undefined}
                  >
                    <TableCell>
                      {isGlobalAdmin ? (
                        <Link
                          className={hoverLink}
                          to={`/courses/${course?.id}/overview`}
                        >
                          {highlightText(course.name, query)}
                        </Link>
                      ) : (
                        course.name
                      )}
                    </TableCell>
                    {isGlobalAdmin && (
                      <TableCell>{course.lessons_count}</TableCell>
                    )}
                    {isGlobalAdmin && (
                      <TableCell>
                        {course.schools_count > 0 && (
                          <Link
                            className={hoverLink}
                            to={`/courses/${course.id}/schools`}
                          >
                            {course.schools_count} Schools
                          </Link>
                        )}
                      </TableCell>
                    )}
                    <TableCell>
                      {course.active_students_count > 0 ? (
                        <Link
                          className={hoverLink}
                          to={`/students?q%5Benrollments_course_id_eq%5D=${course.id}`}
                        >
                          {course.active_students_count}
                        </Link>
                      ) : (
                        course.active_students_count
                      )}
                    </TableCell>
                    <TableCell>{course.students_count}</TableCell>
                    {isGlobalAdmin && <TableCell>-</TableCell>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Pagination>
        </Card>
      </div>
    </div>
  );
}
