import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import PageLoader from "../../../../../../UI/PageLoader";
import CardLink from "../../../../../../UI/Card/CardLink";
import VisitForm from "./VisitFormMobile";

export default function VisitsMobile({ enrollment, decisionOptions }) {
  if (!enrollment) return <PageLoader className="h-[60vh]" />;

  const [formOpen, setFormOpen] = useState();
  const [updatedEnrollment, setUpdatedEnrollment] = useState(enrollment);
  const visits = updatedEnrollment?.visits;

  let sortedVisits = visits.sort((a, b) => new Date(a.date) - new Date(b.date));

  return sortedVisits.length ? (
    sortedVisits.map((visit) => (
      <CardLink
        key={visit.id}
        className="mb-4 last:mb-10 block"
        cardClassName={twMerge(
          "rounded-2xl p-0 py-2",
          formOpen === visit.id && "z-10"
        )}
        onClick={() => {
          setFormOpen(visit.id);
        }}
        activeEffect={formOpen !== visit.id}
      >
        <span
          className={twMerge(
            "flex items-center justify-between p-4",
            visit.completed && "border-b border-gray-500/10"
          )}
        >
          <span className="block text-lg leading-none">
            {Intl.DateTimeFormat("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              timeZone: "UTC",
            }).format(new Date(visit.date))}
          </span>
        </span>

        <VisitForm
          open={formOpen === visit.id}
          enrollment={updatedEnrollment}
          visit={visit}
          setFormOpen={setFormOpen}
          setUpdatedEnrollment={setUpdatedEnrollment}
          decisionOptions={decisionOptions}
        />
      </CardLink>
    ))
  ) : (
    <div className="text-center py-10 text-gray-500">
      No visits found for this enrollment.
    </div>
  );
}
