import React, { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { Outlet, useLoaderData } from "react-router-dom";
import { useMobileNavigation } from "../../../contexts/MobileNavigationContext";
import CourseNav from "./CourseNav";

export default function Course() {
  const course = useLoaderData("course").data;
  const { setMobileNavigation } = useMobileNavigation();
  const [navOpen, setNavOpen] = useState(true);

  useEffect(() => {
    if (course) {
      setMobileNavigation({
        title: course.name,
        links: [
          {
            name: "Overview",
            href: `/courses/${course.id}/overview`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Lessons",
            href: `/courses/${course.id}/lessons`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Decisions",
            href: `/courses/${course.id}/decisions`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Products",
            href: `/courses/${course.id}/products`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Schools",
            href: `/courses/${course.id}/schools`,
            show: true, // TODO: Add permission check
          },
        ],
      });
    }

    return () => {
      setMobileNavigation(null);
    };
  }, [course]);

  return (
    <div>
      <div
        className={twMerge(
          !navOpen && "mb-7",
          "md:mb-0 mb-4 flex items-center justify-center text-center"
        )}
      >
        <h1 className="text-2xl">{course.name}</h1>
        <EllipsisVerticalIcon
          className="md:block hidden ml-5 w-6 cursor-pointer"
          onClick={() => setNavOpen(!navOpen)}
        />
      </div>

      <div className="relative md:block hidden">
        <Transition.Root show={navOpen} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div>
              <CourseNav course={course} />
            </div>
          </Transition.Child>
        </Transition.Root>
      </div>

      <Outlet />
    </div>
  );
}
