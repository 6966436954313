import React from "react";
import CardLink from "../../../../UI/Card/CardLink";
import PageLoader from "../../../../UI/PageLoader";

export default function InstructorCoursesMobile({ courses }) {
  if (!courses) return <PageLoader className="h-[50vh]" />;

  return courses.length ? (
    courses.map((course) => (
      <CardLink
        key={course.id}
        className="mb-4 last:mb-10 block"
        cardClassName="rounded-2xl p-4"
      >
        <span className="flex justify-between items-center gap-2">
          <span className="text-[17px] leading-tight">{course.name}</span>
        </span>
      </CardLink>
    ))
  ) : (
    <div className="text-center py-10 text-gray-500">No courses found.</div>
  );
}
