import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import TextArea from "../../../UI/Inputs/TextArea";
import InputGroup from "../../../UI/Inputs/InputGroup";
import Button from "../../../UI/Button";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";

export default function SchoolNotes({ school }) {
  const { setValue, getValues } = useFormContext();
  const [notes, setNotes] = useState([]);

  const initialNotes = () => {
    // Initialize with a blank row only if there are no existing notes
    if (!school?.school_notes?.length) {
      return [{ note: "", _destroy: false }];
    }
    return school?.school_notes || [];
  };

  useEffect(() => {
    setNotes(initialNotes());
  }, [school]);

  return (
    <InputGroup col classNames="md:pl-10">
      <div className="space-y-4 mt-6">
        <h3 className="font-bold mb-3">SCHOOL NOTES</h3>

        {notes.map(
          (note, index) =>
            !note._destroy && (
              <div key={index} className="flex justify-between gap-2">
                <div className="flex-1">
                  <TextArea
                    id={`school_notes_attributes.${index}.note`}
                    rows={3}
                    placeholder="Enter note..."
                    defaultValue={note.note}
                  />
                </div>
                {note.id && (
                  <>
                    <input
                      type="hidden"
                      id={`school_notes_attributes.${index}.id`}
                      defaultValue={note.id}
                    />
                    <input
                      type="hidden"
                      id={`school_notes_attributes.${index}._destroy`}
                      defaultValue={note._destroy || false}
                    />
                  </>
                )}
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => {
                    const currentNotes = [...notes];
                    if (note.id) {
                      // If note has an ID, mark for destruction
                      setValue(
                        `school_notes_attributes.${index}._destroy`,
                        true
                      );
                      currentNotes[index] = { ...note, _destroy: true };
                    } else {
                      // If note has no ID, remove it from the array
                      const formNotes = getValues("school_notes_attributes");
                      formNotes.splice(index, 1);
                      setValue("school_notes_attributes", formNotes);
                      currentNotes.splice(index, 1);
                    }
                    setNotes(currentNotes);
                  }}
                >
                  <TrashIcon className="h-5 w-5" />
                </Button>
              </div>
            )
        )}

        <div className="flex">
          <Button
            type="button"
            variant="primary-outline"
            icon
            onClick={() => {
              const currentNotes = getValues("school_notes_attributes") || [];
              setValue("school_notes_attributes", [
                ...currentNotes,
                { note: "", _destroy: false },
              ]);
              setNotes([...notes, { note: "", _destroy: false }]);
            }}
          >
            <PlusIcon className="h-5 w-5" />
            Add Note
          </Button>
        </div>
      </div>
    </InputGroup>
  );
}
