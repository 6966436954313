import React, { Fragment, useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { Outlet, useLoaderData, useOutletContext } from "react-router-dom";
import { useMobileNavigation } from "../../../contexts/MobileNavigationContext";
import SchoolNav from "./SchoolNav";
import PageActions from "./PageActions";

export default function School() {
  const { setActionMenuModal } = useOutletContext();
  const school = useLoaderData("school").data;
  const { setMobileNavigation } = useMobileNavigation();
  const [navOpen, setNavOpen] = useState(true);

  useEffect(() => {
    if (school) {
      setMobileNavigation({
        title: school.school_name,
        links: [
          {
            name: "Overview",
            href: `/schools/${school.id}/overview`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Instructors",
            href: `/schools/${school.id}/instructors`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Courses",
            href: `/schools/${school.id}/courses`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Seminars",
            href: `/schools/${school.id}/seminars`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Students",
            href: `/schools/${school.id}/students`,
            show: true, // TODO: Add permission check
          },
        ],
      });
    }

    return () => {
      setMobileNavigation(null);
    };
  }, [school]);

  return (
    <div>
      <div
        className={twMerge(
          !navOpen && "mb-7",
          "md:mb-0 mb-4 flex items-center justify-center text-center"
        )}
      >
        <h1 className="md:block hidden text-2xl">
          (#{school?.school_code}) {school?.school_name} |{" "}
          {[school?.school_city, school?.school_state]
            .filter(Boolean)
            .join(", ")}
        </h1>

        <div className="md:hidden block">
          <p>#{school?.school_code}</p>
          <h1 className="text-2xl text-[40px] my-2 tracking-tight">
            {school?.school_name}
          </h1>
          <p>
            {[school?.school_city, school?.school_state]
              .filter(Boolean)
              .join(", ")}
          </p>
        </div>

        <EllipsisVerticalIcon
          className="md:block hidden ml-5 w-6 cursor-pointer"
          onClick={() => setNavOpen(!navOpen)}
        />
      </div>

      <div className="relative md:block hidden">
        <Transition.Root show={navOpen} as={Fragment}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div>
              <SchoolNav school={school} />
              <div className="absolute top-2 right-0">
                <PageActions schoolId={school.id} />
              </div>
            </div>
          </Transition.Child>
        </Transition.Root>
      </div>

      <Outlet context={{ setActionMenuModal }} />
    </div>
  );
}
