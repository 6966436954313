import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import PageLoader from "../../../../../../UI/PageLoader";
import CardLink from "../../../../../../UI/Card/CardLink";
import ReminderForm from "./ReminderFormMobile";

export default function RemindersMobile({ enrollment }) {
  if (!enrollment) return <PageLoader className="h-[60vh]" />;

  const [formOpen, setFormOpen] = useState();
  const [updatedEnrollment, setUpdatedEnrollment] = useState(enrollment);
  const reminders = updatedEnrollment?.reminders;

  let sortedReminders = reminders.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  return sortedReminders.length ? (
    sortedReminders.map((reminder) => (
      <CardLink
        key={reminder.id}
        className="mb-4 last:mb-10 block"
        cardClassName={twMerge(
          "rounded-2xl p-0 py-2",
          formOpen === reminder.id && "z-10"
        )}
        onClick={() => {
          setFormOpen(reminder.id);
        }}
        activeEffect={formOpen !== reminder.id}
      >
        <span
          className={twMerge(
            "flex items-center justify-between p-4",
            reminder.completed && "border-b border-gray-500/10"
          )}
        >
          <span className="block text-lg leading-none">
            {Intl.DateTimeFormat("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
              timeZone: "UTC",
            }).format(new Date(reminder.date))}
          </span>
        </span>

        <ReminderForm
          open={formOpen === reminder.id}
          enrollment={updatedEnrollment}
          reminder={reminder}
          setFormOpen={setFormOpen}
          setUpdatedEnrollment={setUpdatedEnrollment}
        />
      </CardLink>
    ))
  ) : (
    <div className="text-center py-10 text-gray-500">
      No reminders found for this enrollment.
    </div>
  );
}
