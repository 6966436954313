import React from "react";
import CardLink from "../../../../UI/Card/CardLink";
import PageLoader from "../../../../UI/PageLoader";
import BackLink from "../../../../Mobile/BackLink";

export default function SchoolCoursesMobile({ courses, isGlobalAdmin }) {
  if (!courses) return <PageLoader />;

  return (
    <>
      <BackLink path="/schools" label="Courses" />

      {courses?.length ? (
        <div className="pt-4">
          {courses?.map((course) => (
            <CardLink
              key={course.id}
              to={
                isGlobalAdmin
                  ? `/courses/${course.id}/overview`
                  : `/students?q%5Benrollments_course_id_eq%5D=${course.id}`
              }
              className="mb-4 last:mb-10 block"
              cardClassName="rounded-xl p-0 pb-2"
            >
              <span className="block px-4 pt-4 pb-2 border-b border-gray-500/20">
                <span className="block text-lg leading-tight">
                  {course.name}
                </span>
              </span>
              <span className="flex justify-center items-start py-2 m-auto">
                <span className="w-1/3 text-center">
                  <span className="block text-[40px] leading-tight">
                    {course.active_students_count}
                  </span>
                  <span className="block text-xs leading-none">
                    Active Students
                  </span>
                </span>
                <span className="w-1/3 text-center">
                  <span className="block text-[40px] leading-tight">
                    {course.students_count}
                  </span>
                  <span className="block text-xs leading-none">Students</span>
                </span>
                <span className="w-1/3 text-center">
                  <span className="block text-[40px] leading-tight">
                    {course.lessons_count}
                  </span>
                  <span className="block text-xs leading-none">Lessons</span>
                </span>
              </span>
            </CardLink>
          ))}
        </div>
      ) : (
        <div className="text-center py-10 text-gray-500">No courses found.</div>
      )}
    </>
  );
}
